<template>
  <div class="app-container">
    <el-breadcrumb separator="/" class="breadcrumb2">
      <el-breadcrumb-item :to="{ path: '/zuzhi/home' }"
        >我的组织</el-breadcrumb-item
      >
    </el-breadcrumb>
    <el-card>
      <i class="el-icon-folder"></i>
      <span style="margin-left:10px">我的组织</span>
      <div class="search-bar">
        <el-input
          v-model="formInline.oname"
          placeholder="请输入组织名称进行查询"
          style="width:240px;"
          size="small"
          class="inputstyle"
          @keydown.enter.native="searchOrg"
          suffix-icon="el-icon-search"
        >
        </el-input>
        <el-button plain type="primary" size="small" @click="clearSearch"
          >重置</el-button
        >
        <el-button type="primary" size="small" @click="searchOrg"
          >查询</el-button
        >
      </div>
      <el-table
        :data="listData"
        border
        stripe
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column
          label="序号"
          type="index"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          label="组织码"
          prop="ocode"
          align="center"
        ></el-table-column>
        <el-table-column
          label="组织名称"
          prop="oname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="地址"
          prop="address"
          align="center"
        ></el-table-column>
        <el-table-column
          label="邮箱"
          prop="email"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          sortable
          label="操作"
          align="center"
          width="350"
        >
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="openModify(scope.row)"
              >修改</el-button
            >
            <el-button
              plain
              type="primary"
              size="mini"
              @click="handleApply(scope.$index, scope.row)"
              >加入组织</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="handleDetail(scope.$index, scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next,jumper"
          :current-page.sync="formInline.pageNum"
          :page-size="formInline.pageSize"
          :page-sizes="[5, 10, 15]"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
    <!--加入组织-->
    <el-dialog :visible.sync="dialogApllyVisible" width="60%">
      <div class="search-container">
        <span class="search">组织码</span>
        <el-input
          v-model="searchCode"
          placeholder="请输入申请加入的组织码"
          style="width:240px;"
          size="small"
          suffix-icon="el-icon-search"
          @keydown.native.enter="searchOrganization"
        >
        </el-input>
        <el-button type="primary" size="mini" @click="searchOrganization"
          >搜索</el-button
        >
      </div>
      <div class="search-result">
        <el-table :data="organizationSearch" border stripe>
          <el-table-column
            prop="ocode"
            label="组织码"
            align="center"
          ></el-table-column>
          <el-table-column
            label="组织管理员姓名"
            prop="name"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="oname"
            label="组织单位名称"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="applyToJoin(scope.row)"
                type="warning"
                plain
                >申请</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeApplyDialog">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="组织修改" :visible.sync="modifyDialogVision" width="45%">
      <div class="content">
        <el-form :model="modifyForm" :rules="rules" ref="modifyForm">
          <el-form-item prop="phone">
            <el-input
              v-model="modifyForm.phone"
              placeholder="请输入联系号码"
              class="createdInput"
              clearable
            >
              <template #prepend>
                <i class="el-icon-mobile"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model="modifyForm.email"
              placeholder="请输入邮箱"
              class="createdInput"
              clearable
            >
              <template #prepend> <i class="el-icon-message"></i> </template
            ></el-input>
          </el-form-item>
          <el-form-item prop="address">
            <el-input
              v-model="modifyForm.address"
              placeholder="请输入地址"
              class="createdInput"
              clearable
            >
              <template #prepend> <i class="el-icon-location"></i> </template
            ></el-input>
          </el-form-item>
          <el-form-item prop="oname">
            <el-input
              v-model="modifyForm.oname"
              placeholder="请输入修改后的组织名称"
              class="createdInput"
              clearable
            >
              <template #prepend>
                <i class="el-icon-office-building"></i> </template
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModifyDialog('modifyForm')">取 消</el-button>
        <el-button type="primary" @click="modifyOrganization('modifyForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
const defaultFormInline = {
  pageNum: 1,
  pageSize: 5,
  oname: null
}
export default {
  data() {
    return {
      uid: '', //用户id
      sid: '', //上级的id
      searchName: '',
      modifyDialogVision: false, //修改
      modifyForm: {},
      listData: [],
      // 分页
      formInline: {
        oname: '',
        pageNum: 1,
        pageSize: 5
      },
      total: 0,
      dialogApllyVisible: false,
      searchCode: '', // 用户输入的组织码
      organizationSearch: [], // 搜索结果
      rules: {
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          {
            pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
            message: '请输入正确的电话号码',
            trigger: 'blur'
          }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change']
          }
        ],
        oname: [{ required: true, message: '请输入组织名称', trigger: 'blur' }],
        address: [{ required: true, message: '请输入地址', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.uid = window.sessionStorage.getItem('uid')
    this.getList()
  },
  methods: {
    //打开修改框
    openModify(row) {
      this.modifyForm = { ...row }
      this.modifyDialogVision = true
    },
    //关闭修改弹框
    closeModifyDialog(formName) {
      this.modifyDialogVision = false
    },
    //修改组织
    modifyOrganization(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post(
            '/org/updateorg',
            qs.stringify({
              address: this.modifyForm.address,
              email: this.modifyForm.email,
              oname: this.modifyForm.oname,
              phone: this.modifyForm.phone,
              oid: this.modifyForm.oid
            })
          )
          if (res.code === 200) {
            this.getList()
            this.closeModifyDialog(formName)
            this.$message({
              type: 'success',
              message: '修改成功'
            })
          } else {
            this.$message({
              type: 'error',
              message: '修改失败'
            })
          }
        } else {
          return false
        }
      })
    },
    //重置
    clearSearch() {
      this.formInline = Object.assign({}, defaultFormInline)
      this.getList()
    },
    //查询
    async searchOrg() {
      const { data: res } = await this.$http.get('/org/selectbyoname', {
        params: {
          oname: this.formInline.oname,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize,
          uid: this.uid
        }
      })
      if (res.data != null) {
        this.listData = res.data
        this.total = res.total
        this.$message.success('查询成功')
      }
    },
    // 实现分页
    // 更改了每页大小
    handleSizeChange(val) {
      this.formInline.pageNum = 1
      this.formInline.pageSize = val
      this.getList()
    },
    // 更改了第几页
    handleCurrentChange(val) {
      this.formInline.pageNum = val
      this.getList()
    },
    //查看我的组织详情
    handleDetail(index, row) {
      // window.sessionStorage.setItem('groupCode', row.groupCode)
      // window.sessionStorage.setItem('fatherName', row.oname)
      // window.sessionStorage.setItem('cid', row.cid)
      // window.sessionStorage.setItem('acttype', row.actType)
      // window.sessionStorage.setItem('createtime', row.createTime)
      // window.sessionStorage.setItem('className', row.className)
      // window.sessionStorage.setItem('actId', row.actId)
      // window.sessionStorage.setItem('soid', row.oid)
      this.$router.push({
        path: '/zuzhi/myOrganizationList/myOrganizationList',
        query: { soid: row.oid, fatherName: row.oname }
      })
    },
    //获取列表组织
    async getList() {
      const { data: res } = await this.$http.get('/org/selectbyuid', {
        params: {
          uid: this.uid,
          pageNum: this.formInline.pageNum,
          pageSize: this.formInline.pageSize
        }
      })
      if (res != null) {
        this.listData = res.data
        this.total = res.total
      }
    },
    //将加入组织
    handleApply(index, row) {
      this.sid = row.oid
      this.dialogApllyVisible = true
    },
    //搜索
    async searchOrganization() {
      try {
        const { data: res } = await this.$http.get('/org/selectbyocode', {
          params: {
            ocode: this.searchCode
          }
        })
        if (res.code == 200) {
          this.organizationSearch = [res.data]
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      } catch (error) {
        this.$message.error('搜索失败,请稍后重试')
      }
    },
    //申请
    async applyToJoin(row) {
      try {
        const { data: res } = await this.$http.post(
          '/org/applygroup',
          qs.stringify({
            oid: this.sid,
            sid: row.oid
          })
        )
        if (res.code == 200) {
          this.$message.success(res.message)
          this.dialogApllyVisible = false
        } else {
          this.$message.error(res.message)
        }
      } catch (err) {
        this.$message.error('申请加入失败，重重试')
      }
    },
    //关闭弹框
    closeApplyDialog() {
      this.dialogApllyVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  .inputstyle {
    margin-right: 10px;
  }
}
.search-container {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  .search {
    color: #409eff;
    font-size: 25px;
    font-weight: bold;
    margin-right: 5px;
    align-items: center;
  }
  .el-button {
    font-size: 16px;
  }
}

.el-dialog {
  box-sizing: border-box;
}
.el-dialog__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.el-dialog__header {
  padding: 20px 20px 10px;
  text-align: center;
}

.el-dialog__body {
  padding: 20px;
}

.el-dialog__footer {
  padding: 10px 20px;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100%; */
}
.search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .search-bar {
    .inputstyle {
      margin-right: 10px;
    }
  }
}
</style>
